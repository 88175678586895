<template>
  <div class="card edit-customer-card">
    <h6 class="card-header">
      <i class="fa-duotone fa-pen"></i>&nbsp;
      <template v-if="c & c.id">
        {{ $t("edit.editCustomer") }}
      </template>
      <template v-else>{{ $t("edit.newCustomer") }}</template>
      <div class="btn-group float-end">
        <button class="btn btn-outline-primary btn-sm" @click="updateCustomer()"><i
          class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
        </button>
        <button class="btn btn-outline-secondary btn-sm " @click="cancelEdit()"><i
          class="fa-duotone fa-xmark-circle"></i> {{ $t("globals.cancel") }}
        </button>
      </div>
    </h6>
    <div class="card-body">
      <ul class="tabNav subtabs">
        <li :class="(activeTab === 'customersettings') ? 'active' : ''"
            @click="activeTab = 'customersettings';"><i class="fa-duotone fa-user-chart"></i>
          {{ $t("edit.customerData") }}
        </li>
        <li :class="(activeTab === 'basicsettings') ? 'active' : ''"
            @click="activeTab = 'basicsettings';"><i class="fa-duotone fa-user-cog"></i>
          {{ $t("documents.baseSettings") }}
        </li>
        <li :class="(activeTab === 'paymentsettings') ? 'active' : ''" v-if="(getMenu && getMenu.accounting)"
            @click="activeTab = 'paymentsettings';"><i class="fa-duotone fa-file-contract"></i>
          {{ $t("documents.billingData") }}
        </li>
      </ul>
      <template v-if="!c.id">
        <div class="previewCustomers">
          <template  v-if="previewCustomers && previewCustomers.length > 0">
            <h6>Übereinstimmungen:</h6>
          <template v-for="pc in previewCustomers">
            <div class="previewCustomer">
              <template v-if="pc.c_company">
                <strong>{{ pc.c_company }}</strong><br>
              </template>
              <template v-if="pc.c_firstname || pc.c_lastname">
                {{ pc.c_firstname }} {{ pc.c_lastname }}<br></template>
              <template v-if="pc.a_firstname || pc.a_lastname">
                {{ pc.a_firstname }} {{ pc.a_lastname }}<br>
              </template>
              <template v-if="pc.c_email">
                {{ pc.c_email }}<br></template>
              <template v-if="pc.c_street ">
                {{ pc.c_street }} <br>
              </template>
              <template v-if="pc.c_city || pc.c_zipcode">
                {{ pc.c_zipcode }} {{ pc.c_city }}<br></template>

              <hr>
            </div>
          </template></template>
        </div>
      </template>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active pt-3" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
             tabindex="0" v-if="activeTab === 'customersettings'">
          <div class="row mb-3">
            <div class="col-12 col-lg-3">
              <div class="form-group" tooltip="Wird automatisch vergeben, wenn leer" position="top">
                <label>{{ $t("globals.customerNumber") }}</label>
                <input class="form-control" v-model="c.c_customer_id">
              </div>
            </div>
            <div class="col-12 col-lg-9">
              <div class="form-group">
                <label>{{ $t("globals.company") }}</label>
                <input class="form-control" v-model="c.c_company" @keyup="getPreviewCustomers()">
              </div>
            </div>
          </div>
          <div class="contactDataCustomerEdit">
            <h5>{{ $t("globals.contactData") }}</h5>
            <div class="row private">
              <div class="col-12 col-lg-2">
                <div class="form-group">
                  <label>{{ $t("globals.salutation") }}</label>
                  <input class="form-control" v-model="c.c_title">
                </div>
              </div>
              <div class="col-12 col-lg-2">
                <div class="form-group">
                  <label>Namenszusatz</label>
                  <input class="form-control" v-model="c.c_suffix">
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label>{{ $t("globals.firstName") }}</label>
                  <input class="form-control" v-model="c.c_firstname" @keyup="getPreviewCustomers()">
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label>{{ $t("globals.surname") }}</label>
                  <input class="form-control" v-model="c.c_lastname" @keyup="getPreviewCustomers()">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="form-group">
                <label>{{ $t("globals.email") }}</label>
                <input class="form-control" v-model="c.c_email" @keyup="getPreviewCustomers()">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-3">
              <div class="form-group">
                <label>{{ $t("globals.state") }}</label>
                <multiselect v-model="c.c_country" :id="'country'"
                             :custom-label="nameWithLang"
                             :valueProp="'id'"
                             :options="getSelectedCountries"
                             :multiple="false"
                             :label="'lang_name'"
                             :closeOnSelect="true"
                             :searchable="true"
                             :allow-empty="true"
                             :clearOnSelect="false"
                             :showLabels="false"
                             :placeholder="'Land wählen'"
                             :trackBy="'lang_name'"
                             :canClear="false"
                ></multiselect>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-3">
              <div class="form-group">
                <label>{{ $t("edit.streetPOBox") }}</label>
                <input class="form-control" v-model="c.c_street" @keyup="getPreviewCustomers()">
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-2">
              <div class="form-group">
                <label>{{ $t("globals.zipCode") }}</label>
                <input class="form-control" v-model="c.c_zipcode" @keyup="getPreviewCustomers()">
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-4">
              <div class="form-group">
                <label>{{ $t("globals.location") }}</label>
                <input class="form-control" v-model="c.c_city" @keyup="getPreviewCustomers()">
              </div>
            </div>
          </div>
          <div class="row mb-3 ">
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>{{ $t("globals.phone") }}</label>
                <input class="form-control" v-model="c.c_phone" @keyup="getPreviewCustomers()">
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>Telefon geschäftlich</label>
                <input class="form-control" v-model="c.c_phone2">
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>{{ $t("globals.mobilePhone") }}</label>
                <input class="form-control" v-model="c.c_handy">
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>{{ $t("globals.faxMachine") }}</label>
                <input class="form-control" v-model="c.c_fax">
              </div>
            </div>
          </div>
          <h5>{{ $t("edit.moreInfos") }}</h5>
          <div class="row">
            <div class="col-12 col-lg">
              <div class="form-group">
                <label>{{ $t("globals.business") }}</label>
                <input class="form-control" v-model="c.c_branch">
              </div>
            </div>
            <div class="col-12 col-lg">
              <div class="form-group">
                <label>{{ $t("globals.companyForm") }}</label>
                <input class="form-control" v-model="c.c_business">
              </div>
            </div>
            <div class="col-12 col-lg">
              <div class="form-group">
                <label>{{ $t("globals.website") }}</label>
                <input class="form-control" v-model="c.c_website">
              </div>
            </div>
            <div class="col-12 col-lg private">
              <div class="form-group">
                <label>Funktion</label>
                <input class="form-control" v-model="c.funktion">
              </div>
            </div>
          </div>
          <div class="row private">
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>Geschlecht</label>
                <input class="form-control" v-model="c.c_gender">
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>{{ $t("globals.placeOfBirth") }}</label>
                <input class="form-control" v-model="c.birthday_city">
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>{{ $t("globals.dateOfBirth") }}</label>
                <VueDatePicker v-model="c.c_birthday" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                               :placeholder="$t('globals.selectDateOfBirth')" :auto-apply="true"
                               :close-on-auto-apply="true" locale="de" :enable-time-picker="false" cancel-text="X"
                               :select-text="this.$t('glboals.choose')" :teleport="true">
                </VueDatePicker>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade show active pt-3" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
             tabindex="0" v-if="activeTab === 'basicsettings'">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.customerCategory") }}</label>
                <multiselect v-model="c.c_catid" :label="'title'" :options="getConfigs.categories_customers"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :closeOnSelect="true"
                             :canClear="false"
                             :placeholder="'Kategorie wählen'"
                             :trackBy="'id'"
                ></multiselect>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.customerStatus") }}</label>
                <multiselect v-model="c.c_state" :label="'name'" :options="getConfigs.customerState"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="false"
                             :closeOnSelect="true"
                             :placeholder="'Status wählen'"
                             :trackBy="'id'"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="row" v-if="c.c_state ==='5'">
            <div class="col-12 col-lg-6 offset-lg-3">
              <div class="alert alert-warning">
                <i class="fa-duotone fa-info-circle"></i> {{ $t("edit.customerDead") }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.accountManager") }}</label>
                <multiselect v-model="c.c_owner" :label="'e_name'" :options="getEmployees"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="false"
                             :closeOnSelect="true"
                             :placeholder="'Betreuer wählen'"
                             :trackBy="'id'"
                ></multiselect>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.customerPriority") }}</label>
                <multiselect v-model="c.c_prio" :label="'name'" :options="getConfigs.customerPrio"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="false"
                             :closeOnSelect="true"
                             :placeholder="'Priorität wählen'"
                             :trackBy="'id'"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.acquisitionStatus") }}</label>
                <multiselect v-model="c.c_akquise" :label="'name'" :options="getConfigs.customerAcquisition"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="false"
                             :closeOnSelect="true"
                             :placeholder="'Akquise-Stand wählen'"
                             :trackBy="'id'"
                ></multiselect>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.customerType") }}</label>
                <multiselect v-model="c.c_type" :label="'name'" :options="getConfigs.customerTypes"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="false"
                             :closeOnSelect="true"
                             :placeholder="'Kunden-Typ wählen'"
                             :trackBy="'id'"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.billingLanguage") }}</label>
                <multiselect v-model="c.invoice_lang" :label="'name'" :options="invoiceLangOptions"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="false"
                             :closeOnSelect="true"
                             :placeholder="'Rechnungssprache wählen'"
                             :trackBy="'id'"
                ></multiselect>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("globals.tags") }}</label>
                <multiselect v-model="c.tags" :id="'tags'"
                             :valueProp="'id'"
                             :options="getConfigs.customerTags"
                             :multiple="true"
                             :label="'name'"
                             :closeOnSelect="false"
                             :allow-empty="true"
                             :create-option="true"
                             :clearOnSelect="false"
                             :searchable="true"
                             :taggable="true"
                             @tag="addTag"
                             :placeholder="$t('globals.chooseTags')"
                             :tag-placeholder="$t('globals.insertNewTag')"
                             track-by="name"
                             mode="tags"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>Qualifizierter Lead seit</label>
                <VueDatePicker v-model="c.qualified_akquise" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                               :auto-apply="true" :close-on-auto-apply="true" locale="de" :enable-time-picker="false"
                               cancel-text="X" :select-text="this.$t('glboals.choose')" :teleport="true">
                </VueDatePicker>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.customerLead") }}</label>
                <multiselect v-model="c.c_lead" :label="'name'" :options="getConfigs.customerLead"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="false"
                             :closeOnSelect="true"
                             :placeholder="'Lead-Quelle wählen'"
                             :trackBy="'id'"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>{{ getTranslation("structure1") }}</label>
                <multiselect v-model="c.structure1" :label="'name'" :options="getConfigs['customerStructure_1']"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="true"
                             :closeOnSelect="true"
                             :placeholder='getTranslation("structure1")+ " wählen"'
                             :trackBy="'id'"
                ></multiselect>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>{{ getTranslation("structure2") }}</label>
                <multiselect v-model="c.structure2" :label="'name'" :options="getConfigs['customerStructure_2']"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="true"
                             :closeOnSelect="true"
                             :placeholder='getTranslation("structure2")+ " wählen"'
                             :trackBy="'id'"
                ></multiselect>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>{{ getTranslation("structure3") }}</label>
                <multiselect v-model="c.structure3" :label="'name'" :options="getConfigs['customerStructure_3']"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="true"
                             :closeOnSelect="true"
                             :placeholder='getTranslation("structure3")+ " wählen"'
                             :trackBy="'id'"
                ></multiselect>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>{{ getTranslation("structure4") }}</label>
                <multiselect v-model="c.structure4" :label="'name'" :options="getConfigs['customerStructure_4']"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :multiple="false"
                             :canClear="true"
                             :closeOnSelect="true"
                             :placeholder='getTranslation("structure4")+ " wählen"'
                             :trackBy="'id'"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="form-group">
                <label>Informationen</label>
                <Editor2 v-model="c.c_infos"/>
              </div>
            </div>
          </div>
          <Holzkraft v-if="getPlugins && getPlugins.infos && getPlugins.infos.holzkraft" mode="editCustomer"/>
        </div>
        <div class="tab-pane fade show active pt-3" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab"
             tabindex="0" v-if="activeTab === 'paymentsettings'">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.defaultInvoiceType") }}</label>
                <select class="form-select" v-model="c.invoice_type">
                  <option value="1">{{ $t("edit.paymentMethod.default") }}</option>
                  <option value="2">{{ $t("edit.paymentMethod.separateDirectDebit") }}</option>
                  <option value="3">{{ $t("edit.paymentMethod.discount") }}</option>
                  <option value="5">{{ $t("edit.paymentMethod.prePay") }}</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.salesTaxId") }}</label>
                <input class="form-control" v-model="c.c_ustid">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.invoiceRecipient") }}</label>
                <input class="form-control" v-model="c.invoice_mail">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.taxRegime") }}</label>
                <select class="form-select" v-model="c.c_tax_rule">
                  <option :value="tax.id" v-for="tax in getConfigs.customerTax" v-bind:key="tax.id">{{
                      tax.rule_name
                    }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.vendorNumber") }}</label>
                <input class="form-control" v-model="c.reseller_id">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.discountPercent") }}</label>
                <input class="form-control" type="number" v-model="c.c_discount">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.vendorAccounting") }}</label>
                <input class="form-control" type="number" v-model="c.account_number">
                <template v-if="c.account_number === '0' && c._nextAccountNumber"><small
                  @click="c.account_number = c._nextAccountNumber">Für Kunden leer lassen. Für Lieferanten nächster
                  Vorschlag: {{ c._nextAccountNumber }}</small></template>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.defaultBehaviorAccount") }}</label>
                <input class="form-control" type="number" v-model="c.account_outlay">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.defaultBehaviorBookingKeys") }}</label>
                <input class="form-control" type="number" v-model="c.account_key">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.defaultBehaviorCostPosition") }}</label>
                <input class="form-control" type="number" v-model="c.account_cost">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.projectIdCommaSeparated") }}</label>
                <input class="form-control" v-model="c.project_ids">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.freeMonthlyQuota") }}</label>
                <input class="form-control" type="number" v-model="c.project_free_hours_monthly">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.netSalesStartAmount") }}</label>
                <input class="form-control" type="number" v-model="c.account_startsum_netto">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{ $t("edit.grossSalesStartAmount") }}</label>
                <input class="form-control" type="number" v-model="c.account_startsum_brutto">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <label>Vermittelt durch:</label>
              <customersearch v-model="c.affiliate_customer"/>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import indexedDbFunctions from "@/assets/js/indexedDbFunctions";
import Multiselect from '@vueform/multiselect'
import {useToast} from "vue-toastification";
import Editor2 from "@/components/Editor2.vue";
import Holzkraft from "@/routing/plugins/holzkraft/subs/projects.vue";
import customersearch from "@/filter/components/customersearch.vue";

export default {
  name: 'EditCustomer',
  emits: ['close'],
  data() {
    return {
      c: {
        c_customer_id: "",
        c_company: "",
        c_title: "",
        c_suffix: "",
        c_firstname: "",
        c_lastname: "",
        c_email: "",
        c_country: "",
        c_street: "",
        c_zipcode: "",
        c_city: "",
        c_phone: "",
        c_phone2: "",
        c_handy: "",
        c_fax: "",
        c_branch: "",
        c_business: "",
        c_website: "",
      },
      localCountry: null,
      invoiceLangOptions: [
        {id: 'de', name: this.$t('globals.german')},
        {id: 'en', name: this.$t('globals.english')},
      ],
      toast: {
        class: 'bg-info',
        message: this.$t("edit.processingData"),
      },
      activeTab: 'customersettings',
      overrides: {
        edit: {}
      },
      previewCustomers: [],
    }
  },
  props: {
    comefrom: String
  },
  components: {
    VueDatePicker,
    Multiselect,
    Editor2,
    Holzkraft,
    customersearch
  },
  computed: {
    ...mapGetters(["getPlugins", "getEmployees", "customerActive", "getConfigs", "getSelectedCountries", "getMenu", "getUser"])
  },
  methods: {
    nameWithLang({lang_code, lang_name}) {
      return `${lang_code} - ${lang_name}`
    },
    cancelEdit() {
      if (this.comefrom === 'documents') {
        this.$emit('close');
      } else {
        this.$store.commit("closeModal", "customer")
        if (this.c && this.c.id) {
          this.$router.push({name: "CustomersDetails", params: {id: this.c.id}});
        } else {
          this.$router.push({name: "customers"});
        }
      }
    },
    getPreviewCustomers() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      // Check if at least one of the fields has 3 or more characters
      const {c_firstname, c_lastname, c_phone, c_company, c_street, c_zipcode, c_city, c_email} = this.c;
      if (
        (c_firstname && c_firstname.length >= 3) ||
        (c_lastname && c_lastname.length >= 3) ||
        (c_phone && c_phone.length >= 3) ||
        (c_company && c_company.length >= 3) ||
        (c_street && c_street.length >= 3) ||
        (c_zipcode && c_zipcode.length >= 3) ||
        (c_city && c_city.length >= 3) || (c_email && c_email.length >= 3)
      ) {
        axios.get(this.$store.state.apiUrl + "customer/search?limit=0&addCheck=true&c_firstname=" + c_firstname + "&c_lastname=" + c_lastname + "&c_phone=" + c_phone + "&c_company=" + c_company + "&c_street=" + c_street + "&c_zipcode=" + c_zipcode + "&c_city=" + c_city + "&c_email="+ c_email, {headers})
          .then((r) => {
            this.previewCustomers = r.data.result.data;
          })
          .catch((e) => {
            this.$store.commit("handleApiResponse", e.response);
          });
      } else {
        this.previewCustomers = [];
      }
    },
    updateSelected() {
      this.c.c_country = this.localCountry.id;
    },
    addTag(newTag) {
      // random id
      this.c.tags.push({name: newTag, code: Math.random().toString(36).substr(2, 9)})
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getTranslation(filterKey) {
      // Check for an override first
      const overrideKey = this.overrides.edit[filterKey];
      if (overrideKey) {
        return overrideKey;
      }
      // Fallback to the default translation
      return this.$t('edit.' + filterKey);
    },
    createCustomer() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      //const headers = {};
      axios.post(this.$store.state.apiUrl + "customer/", this.c, {headers}).then(() => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.customerCreated"));
        this.$store.commit("closeModal", "customer");
        this.$store.dispatch('customersFilter');
        // reset active customer
        this.$store.commit('setActiveItem', {type: 'customer', data: {}});
      }).catch((e) => {
        this.$store.commit("handleApiResponse", e.response);
      });
    },
    updateCustomer() {
      if (!this.c.id) {
        this.createCustomer();
        return;
      }
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "customer/" + this.c.id, this.c, {headers}).then((r) => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.dataSaved"));
        if (this.comefrom === 'documents') {
          this.$emit('close');
        } else {
          this.$store.dispatch('customersFilter');
          this.$store.commit('setActiveItem', {type: 'customer', data: r.data.result.data});
          this.$router.push({name: "CustomersDetails", params: {id: r.data.result.data.id}});
        }
      }).catch((e) => {
        this.$store.commit("handleApiResponse", e.response);
      });
    }
  },
  watch: {
    customerActive: function () {
      this.c = this.customerActive;
    }
  },
  created() {
    // get current url
    this.c = this.customerActive;
    if (!this.c.id) {
      this.localCountry = this.getConfigs.countries.find((country) => country.default === '1');
      const defaultCountry = this.getConfigs.countries.find((country) => country.default === '1');
      if (defaultCountry) {
        this.c.c_country = defaultCountry.id;
      } else {
        // Fallback to the first country if no default is marked
        this.c.c_country = this.getConfigs.countries[0].id;
      }
      // Prefill selects
      this.c.c_catid = this.getConfigs.categories_customers[0].id;
      const defaultState = this.getConfigs.customerState.find(state => state.use_as_default === '1');
      if (defaultState) {
        this.c.c_state = defaultState.id;
      } else {
        // Fallback to the first customerState if no default is marked
        this.c.c_state = this.getConfigs.customerState[0].id;
      }
      const defaultOwner = this.getEmployees.find(owner => owner.id === this.getUser._isEmployee);
      if (defaultOwner) {
        this.c.c_owner = defaultOwner.id;
      } else {
        // Fallback to the first employee if no default is marked
        this.c.c_owner = this.getEmployees[0].id;
      }
      const defaultPrio = this.getConfigs.customerPrio.find(prio => prio.use_as_default === '1');
      if (defaultPrio) {
        this.c.c_prio = defaultPrio.id;
      } else {
        // Fallback to the first customerPrio if no default is marked
        this.c.c_prio = this.getConfigs.customerPrio[0].id;
      }
      const defaultAkquise = this.getConfigs.customerAcquisition.find(akquise => akquise.use_as_default === '1');
      if (defaultAkquise) {
        this.c.c_akquise = defaultAkquise.id;
      } else {
        // Fallback to the first customerAcquisition if no default is marked
        this.c.c_akquise = this.getConfigs.customerAcquisition[0].id;
      }
      const Lead = this.getConfigs.customerTypes.find(lead => lead.use_as_default === '1');
      if (Lead) {
        this.c.c_lead = Lead.id;
      } else {
        // Fallback to the first customerLead if no default is marked
        this.c.c_lead = this.getConfigs.customerTypes[0].id;
      }
      const defaultCtype = this.getConfigs.customerTypes.find(ctype => ctype.use_as_default === '1');
      if (defaultCtype) {
        this.c.c_type = defaultCtype.id;
      } else {
        // Fallback to the first customerTypes if no default is marked
        this.c.c_type = this.getConfigs.customerTypes[0].id;
      }
      this.c.invoice_lang = 'de';
      const defaultTax = this.getConfigs.customerTax.find(tax => tax.use_as_default === '1');
      if (defaultTax) {
        this.c.c_tax_rule = defaultTax.id;
      } else {
        // Fallback to the first customerTax if no default is marked
        this.c.c_tax_rule = this.getConfigs.customerTax[0].id;
      }
      this.c.invoice_type = "1";
      if (this.getPlugins && this.getPlugins.infos && this.getPlugins.infos.holzkraft) {
        this.c.projects = [
          {
            title: '',
            state: 'prepare',
            start: '',
            end: '',
            plugins: {
              holzkraft: [{
                lieferanschrift_land: '',
                lieferanschrift_strasse: '',
                lieferanschrift_plz: '',
                lieferanschrift_ort: '',
                vertriebsplanung: '',
                projektGesamtkosten: '',
                abschlusswahrscheinlichkeit: '',
                angebotErhalten: '',
                anzahlAnlagen: '',
                gebrauchtanlage: '',
                kaufvertragErhalten: '',
                kwel: '',
                preisProKw: '',
              }]
            }
          }
        ];
      }
    } else {
      this.localCountry = this.getConfigs.countries.find((country) => country.id === this.c.c_country);
    }
    if (this.getConfigs && this.getConfigs.params_values) {
      this.overrides.edit.structure1 = this.getConfigs.params_values.title_structure1;
      this.overrides.edit.structure2 = this.getConfigs.params_values.title_structure2;
      this.overrides.edit.structure3 = this.getConfigs.params_values.title_structure3;
      this.overrides.edit.structure4 = this.getConfigs.params_values.title_structure4;
    }
  },
}
</script>
<style lang="scss">
.nav-pills {
  .nav-item {
    padding: 0 1rem 0 1rem;
    .nav-link {
      border: .125rem solid #5e72e4 !important;
      &.active {
        background-color: #5e72e4 !important;
        color: #fff !important;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
      }
    }
  }
}
</style>
